import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GetGroupedCategories } from 'redux/actions/categories';
import { GetUpcomingSchedules } from 'redux/actions/schedule';
import { useAppContext } from 'context';
import * as microsoftTeams from '@microsoft/teams-js';
import Loading from 'components/Loading';
import dropdowns from 'data/dropdowns';
import Form from './Form';

const TriviaQuizzes = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const {
    categories,
    specialCategory,
    automations,
    GetGroupedCategories: fetchCategories,
    GetUpcomingSchedules: getUpcomingSchedules
  } = props;
  const {
    channelId,
    ts,
    setTs,
    startedVia,
    setStartedVia,
    plan,
    setPlan,
    setEntryPoint,
    setServiceUrl,
    chatId,
    setCurrentUserName
  } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [maxCategories, setMaxCategories] = useState(false);
  const [limitFlag, setLimitFlag] = useState('');
  const [autoScheduleOptIn, setAutoScheduleOptIn] = useState(false);
  const [isAutomationPresent, setAutomationPresent] = useState(false);
  const [formFields, setFormFields] = useState({
    type: 'Instant Quiz',
    categories: [],
    difficulty: 'Random',
    negativemarking: 'No',
    plan: null
  });

  // Updating the context param variables when the game is opened directly
  useEffect(() => {
    setTs((prev) => prev || params.get('ts') || null);
    setStartedVia((prev) => prev || params.get('startedVia') || null);
    setPlan((prev) => prev || params.get('plan') || null);
    setEntryPoint((prev) => prev || params.get('entryPoint') || null);
    setServiceUrl((prev) => prev || params.get('serviceUrl') || null);
    setCurrentUserName((prev) => prev || params.get('userName') || null);
  }, [
    params,
    setTs,
    setStartedVia,
    setPlan,
    setEntryPoint,
    setServiceUrl,
    setCurrentUserName,
    plan
  ]);

  // logic to handle category selection
  const handleCategorySelection = {
    onAdd: (e, item) => {
      // do not add category if the count is more than 5
      if (formFields.categories?.length >= 5) {
        setMaxCategories(true);
      }
      // do not add category if it already exists
      else if (formFields.categories.includes(item.optionText)) {
        return null;
      }
      // add category
      else {
        setFormFields({
          ...formFields,
          categories: [...formFields.categories, item.optionText]
        });
      }
      return null;
    },
    onRemove: (item) => {
      setFormFields({
        ...formFields,
        categories: formFields.categories.filter((i) => i !== item)
      });
      if (formFields.categories?.length <= 5) {
        setMaxCategories(false);
      }
    }
  };

  const fetchData = async (tenantId, teamId, chat, userId) => {
    if (!categories.length) {
      await Promise.all([
        fetchCategories(tenantId),
        getUpcomingSchedules(tenantId, teamId, chat, userId)
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    const channelAutomations = automations?.filter((a) =>
      [channelId, chatId].includes(a.channelId)
    );
    if (channelAutomations.length) {
      setAutoScheduleOptIn(false);
      setAutomationPresent(true);
    }
  }, [automations, channelId, chatId]);

  useEffect(() => {
    microsoftTeams.app.initialize();
    microsoftTeams.app.getContext().then((context) => {
      if (!context.team?.groupId && !context.chat.id && !context.meeting.id) {
        return navigate('/error?cause=quiz_in_personal_chat');
      }
      setFormFields({
        ...formFields,
        plan: plan || params.get('plan') || null
      });
      fetchData(context.user.tenant.id, context.team?.groupId, context.chat?.id, context.user?.id);
      if (context?.meeting?.id) {
        setAutoScheduleOptIn(false);
        setAutomationPresent(true);
      }
      return null;
    });
    microsoftTeams.app.notifySuccess();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    // disable the launch
    setDisable(true);

    // prepare the data to be sent to the task-module
    const formData = {};

    if (formFields.type) {
      formData.type = formFields.type;
      if (formFields.type === 'Self Paced Quiz') {
        formData.selfPaced = true;
      } else {
        formData.selfPaced = false;
      }
    }

    if (formFields.categories?.length) {
      formData.category = formFields.categories?.map(
        (cat) => categories.find((c) => c.header === cat).id
      );
    } else if (specialCategory && specialCategory.id && specialCategory.id.trim() !== '') {
      formData.category = [specialCategory.id];
    } else {
      formData.category = [];
    }

    if (formFields.difficulty && formFields.difficulty !== 'Random') {
      formData.difficulty = dropdowns.difficulty.find(
        (d) => d.content === formFields.difficulty
      )?.key;
    }
    if (formFields.negativemarking) {
      formData.negativeMarking = formFields.negativemarking === 'Yes';
    }
    if (ts && ts !== 'null') {
      formData.ts = ts;
    }
    if (autoScheduleOptIn) {
      formData.autoScheduleOptIn = autoScheduleOptIn;
    }
    if (startedVia) {
      formData.startedVia = startedVia;
    }

    // sending the form data to backend
    microsoftTeams.dialog.url.submit(
      { value: 'quiz_confirm', formData },
      process.env.REACT_APP_TEAMS_APP_ID
    );
  };

  const handleChange = (value, key) => {
    if (formFields.plan === 'BASIC' && ['difficulty', 'negativemarking'].includes(key)) {
      setLimitFlag(key);
      setFormFields({ ...formFields, [key]: formFields[key] });
      // this is to show the warning message which removes after 3 seconds
      setTimeout(() => {
        setLimitFlag('');
      }, 3000);
    } else {
      setFormFields({ ...formFields, [key]: value.optionValue });
    }
  };

  // render the loader
  if (loading || !categories?.length) {
    return (
      <div style={{ marginTop: '15%' }}>
        <Loading height={100} width={100} />
      </div>
    );
  }

  return (
    <Form
      categories={categories}
      maxCategories={maxCategories}
      handleCategorySelection={handleCategorySelection}
      handleChange={handleChange}
      formFields={formFields}
      limitFlag={limitFlag}
      handleSubmit={handleSubmit}
      disable={disable}
      showCheckbox={!isAutomationPresent}
      autoScheduleOptIn={autoScheduleOptIn}
      onCheckboxClick={() => setAutoScheduleOptIn(!autoScheduleOptIn)}
    />
  );
};

TriviaQuizzes.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  GetGroupedCategories: PropTypes.func.isRequired,
  specialCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  GetUpcomingSchedules: PropTypes.func.isRequired,
  automations: PropTypes.array
};

TriviaQuizzes.defaultProps = {
  categories: [],
  specialCategory: { name: '', id: '' },
  automations: false
};

const mapStateToProps = ({
  categories: { categories, specialCategory },
  schedules: { upcoming }
}) => {
  specialCategory =
    specialCategory && specialCategory.name ? specialCategory : { name: '', id: '' };
  // To remove the label for more categories
  const groupedCategories = [];
  categories?.forEach((og) => {
    og.options.forEach((cat) => {
      groupedCategories.push({
        header: cat.name,
        content: og.labelText === '🧩 More categories' ? '' : og.labelText,
        id: cat.id
      });
    });
  });

  return {
    categories: groupedCategories,
    specialCategory,
    automations: upcoming
  };
};

export default connect(mapStateToProps, {
  GetGroupedCategories,
  GetUpcomingSchedules
})(TriviaQuizzes);
