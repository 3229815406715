import React from 'react';
import PropTypes from 'prop-types';
import '../Config/styles.scss';
import { ReactComponent as TeamsLogo } from 'assets/images/teams-logo.svg';
import * as microsoftTeams from '@microsoft/teams-js';
import localStorage from 'utils/localStorage';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
    this.signInUser = this.signInUser.bind(this);
  }

  componentDidMount() {
    microsoftTeams.app.initialize();
    microsoftTeams.app.notifySuccess();
  }

  signInUser() {
    const params = new URLSearchParams(window.location.search);
    const authenticateParameters = {
      url: `${process.env.REACT_APP_TAB_AUTH_START}?origin=${params.get('origin')}`,
      width: 600,
      height: 535
    };

    microsoftTeams.authentication
      .authenticate(authenticateParameters)
      .then((result) => {
        localStorage.setFlag(decodeURIComponent(result.flag));
        localStorage.set('userId', result.userId);
        this.setState({ error: null }, () => {
          if (result.origin === 'tab') {
            window.location.href = `${process.env.REACT_APP_BASE_URL}/tabs/leaderboard`;
          } else if (result.origin === 'taskModule') {
            localStorage.setFlag(decodeURIComponent(result.flag));
            microsoftTeams.dialog.url.submit();
          }
        });
      })
      .catch((reason) => {
        this.setState({ error: reason });
      });
  }

  render() {
    const { error } = this.state;
    const { origin, signInUser: signInUserFromProps } = this.props;
    const params = new URLSearchParams(window.location.search);
    return origin === 'config' ? (
      <>
        <div className='hint'>Sign into your Trivia account</div>
        <div
          data-testid='signin-button'
          className='signin-button'
          onClick={signInUserFromProps || this.signInUser}
        >
          <TeamsLogo />
          <span>Sign in with Microsoft Teams</span>
        </div>
      </>
    ) : (
      <div className={`config-container ${params.get('theme')}`}>
        <div className='content-wrapper'>
          <div data-testid='hint' className='hint'>
            Sign into your Trivia account
          </div>
          <div
            data-testid='signin-button'
            className='signin-button'
            onClick={signInUserFromProps || this.signInUser}
          >
            <TeamsLogo />
            <span>Sign in with Microsoft Teams</span>
          </div>
          {error && (
            <div data-testid='error-message' className='error'>
              {error}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  signInUser: PropTypes.func,
  origin: PropTypes.oneOf(['taskModule', 'tab', 'config']).isRequired
};

Login.defaultProps = {
  signInUser: null
};

export default Login;
