/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Option, useId, Persona, makeStyles } from '@fluentui/react-components';
import dropdowns from 'data/dropdowns';
import Layout from 'components/TaskModule/Layout';
import { useAppContext } from 'context';
import AutomateTrivia from 'components/TaskModule/AutomateTrivia';
import { getDropdownArray } from 'utils';
import classNames from 'classnames';
import styles from './TriviaQuizzes.module.scss';

const useStyles = makeStyles({
  listbox: {
    maxHeight: '300px'
  }
});

const QuizTypes = [
  { label: 'Instant Quiz', value: 'Instant Quiz' },
  { label: 'Self Paced Quiz', value: 'selfPacedQuiz' }
];

const Form = (props) => {
  const {
    categories,
    maxCategories,
    handleCategorySelection,
    handleChange,
    formFields,
    handleSubmit,
    disable,
    limitFlag,
    showCheckbox,
    onCheckboxClick,
    autoScheduleOptIn
  } = props;
  const { theme, entryPoint } = useAppContext();
  const dropdownId = useId('dropdown');

  const classes = useStyles();

  return (
    <Layout
      route='all-games'
      backButtonText={entryPoint === 'Instant Quiz' ? 'View all Trivia formats' : 'Back'}
      showChannelDetails
      actionButton
      buttonDisabled={disable}
      handleClick={handleSubmit}
    >
      <div
        className={classNames(styles.quizFormContainer, {
          [styles.darkTheme]: theme === 'dark'
        })}
      >
        <div className={styles.pageTitle}>Launch Quizzes</div>

        <label htmlFor={dropdownId}>Quiz Type</label>
        <Dropdown
          placeholder='Choose Quiz Types'
          defaultValue={formFields.type}
          onOptionSelect={(e, value) => handleChange(value, 'type')}
          defaultSelectedOptions={[formFields.type]}
        >
          {QuizTypes.map((type, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Option key={index}>{type.label}</Option>
          ))}
        </Dropdown>
        {maxCategories && (
          <div className={styles.error}>⚠️ You cannot select more than 5 categories.</div>
        )}

        <Dropdown
          multiselect
          listbox={{ className: classes.listbox }}
          placeholder='Choose category or let Trivia shuffle it for you'
          onOptionSelect={handleCategorySelection.onAdd}
        >
          {categories.map((option, index) =>
            option.content ? (
              // eslint-disable-next-line react/no-array-index-key
              <Option key={index} text={option.header}>
                <Persona
                  size='medium'
                  presenceOnly
                  name={option.header}
                  secondaryText={option.content}
                />
              </Option>
            ) : (
              <Option key={option.header}>{option.header}</Option>
            )
          )}
        </Dropdown>

        {limitFlag === 'difficulty' && (
          <div className={styles.error}>⚠️ You cannot select difficulty on your plan</div>
        )}

        {limitFlag === 'negativemarking' && (
          <div className={styles.error}>⚠️ You cannot enable negative marking on your plan</div>
        )}

        <div className={styles.gridContainer}>
          <label htmlFor={dropdownId}>Difficulty</label>
          <label htmlFor={dropdownId}>Negative marking</label>
          <div className={styles.dropdownMobileView}>
            <Dropdown
              className={styles.firstDropdown}
              id={dropdownId}
              placeholder='Random (Default)'
              onOptionSelect={(e, value) => handleChange(value, 'difficulty')}
              defaultSelectedOptions={['Random']}
            >
              {getDropdownArray(dropdowns.difficulty, 'Random', formFields.plan)}
            </Dropdown>
          </div>
          <Dropdown
            className={styles.secondDropdown}
            placeholder='No (Default)'
            id={dropdownId}
            onOptionSelect={(e, value) => handleChange(value, 'negativemarking')}
            defaultSelectedOptions={['No']}
          >
            {dropdowns.negativemarking.map((value) => {
              if (formFields.plan === 'BASIC' && value === 'Yes') {
                return <Option>{`${value} 🔒`}</Option>;
              }
              return <Option>{value}</Option>;
            })}
          </Dropdown>
        </div>
      </div>
      {showCheckbox && <AutomateTrivia value={autoScheduleOptIn} handleChange={onCheckboxClick} />}
    </Layout>
  );
};

Form.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  maxCategories: PropTypes.bool.isRequired,
  handleCategorySelection: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  formFields: PropTypes.shape({
    type: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    difficulty: PropTypes.string.isRequired,
    negativemarking: PropTypes.string.isRequired,
    plan: PropTypes.string.isRequired,
    teamName: PropTypes.string.isRequired
  }).isRequired,
  limitFlag: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  autoScheduleOptIn: PropTypes.bool.isRequired
};

Form.defaultProps = {
  categories: []
};

export default Form;
